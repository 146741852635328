<template>
    <div>
        <van-nav-bar title="集体办理" left-text="" right-text="申请" left-arrow @click-left="url('/')"
            @click-right="url('/company_apply')" />

        <div class="top">
            <div class="title">
                <div class="title-text">申请记录</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>

            <van-cell-group>
                <van-cell v-for="(item,index) in list" :key="index" is-link :url="'/company_user?id=' + item.id"
                    :label="item.create_time">
                    <template slot="title">
                        <div>
                            <van-tag type="warning" v-if="item.company_sale_state==1">佣金到账</van-tag> {{item.company_name+'('+ item.handle_count +'人)'}}
                        </div>
                    </template>
                    <template slot="default">
                        <van-tag type="primary" v-if="item.state==1">审核通过</van-tag>
                        <van-tag type="danger" v-if="item.state==0">关闭</van-tag>
                    </template>
                </van-cell>
            </van-cell-group>

            <div v-if="list.length == 0">
                <van-empty description="列表为空" />
            </div>

        </div>

    </div>
</template>

<script>
    import { oauth } from "@/static/js/oauth";
    import {
        company_list
    } from '@/api/company.js';
    import {
        Dialog
    } from 'vant';
    export default {
        name: 'company',
        data() {
            return {
                list: [],
            }
        },
        mounted() {
            oauth();
            this.get_data();
        },
        methods: {
            //获取订单列表
            get_data() {
                var that = this;
                company_list({}).then(res => {
                    if (res.code == 1) {
                        that.list = res.data;
                    }
                });
            },


            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .van-nav-bar__title,
    /deep/ .van-nav-bar__text {
        color: var(--main-color) !important;
    }

    /deep/ .van-nav-bar__title {
        color: var(--main-color);
        font-family: 'FZCS';
    }

    /deep/ .van-nav-bar__left i {
        color: var(--main-color) !important;
    }

    /deep/ .van-cell__title {
        flex: none;
        width: calc(100% - 80px) !important;
    }

    /deep/ .van-cell__value {
        flex: none;
        width: 80px !important;
    }
</style>